<template>
  <div class="driverdetils">
    <div class="infor">
      <div class="header-img">
        <img src="../../../../public/static/images/vip/duihuan.png" alt="">
      </div>
      <div class="hint">{{inforDetail.name}}每月消费上限</div>
      <div class="price"><span>¥</span>{{inforDetail.money}}</div>
      <div class="modification">每月消费上限 <span @click="show= true,quota = ''">修改</span></div>
    </div>
    <div class="expenditure-record" v-if="recordList.length > 0">
      
      <list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="consume_record"
      >
        <cell v-for="(item,index) in recordList" :key="index" :title="item.text" :label="item.create_time" >
          <template slot="default">
            {{item.type==1?"+":"-"}}{{item.amount}}
          </template>
        </cell>
      </list>
      
    </div>
    <empty description="暂无消费记录" v-else/>
    <popup v-model="show" position="bottom" :style="{height: '30%'}" :safe-area-inset-bottom="true">
      <divider>调整金额</divider>
      <field v-model="quota" label="调整金额" placeholder="请输入调整金额">
        <template slot="button">
          <div class="add-reduce">
            <div class="add-reduce-style" :class="type==2?'add-reduce-active':''" @click="type = 2">减少</div>
            <div class="add-reduce-style" :class="type==1?'add-reduce-active':''" @click="type = 1">增加</div>
          </div>
        </template>
      </field>
      <div class="btn_group">
        <Button class="btn" plain type="info" @click="show = false">取消</Button>
        <Button class="btn" plain type="primary" @click="updateTeamPrice">确定</Button>
      </div>
    </popup>
  </div>
</template>

<script>
import Creditshop from "@/api/creditshop";
import { Cell, Popup, Field, Divider, Button,List,Empty  } from 'vant';
export default {
  components: {
    Cell,
    Popup, Field, Divider, Button,
    List,
    Empty 
  },
  data() {
    return {
      type: "",
      loading: false,
      finished: false,
      show:false,
      value: "100",
      inforDetail: {
        name: "",
        id: "",
        money: ""
      },
      quota: "",
      page: 0,
      recordList: [], // 变更记录
    }
  },
  mounted() {
    this.inforDetail = this.$route.query;
    this.consume_record()
  },
  methods: {
    async consume_record() {
      try {
        this.page ++;
        const res = await Creditshop.consume_record(this.$route.query.id,"","",this.page);
        this.loading = false;
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          this.recordList.push(element)
        }
        if (res.data.length == 0) {
          this.finished = true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 调整额度
    async updateTeamPrice() {
      try {
        if (!this.type) return this.$toast("选择金额变更方式")
        const res = await Creditshop.updateTeamPrice(this.inforDetail.id, this.quota,this.type);
        if (res.code == 200) {
          this.$toast({
            message: res.msg
          });
          this.show = false;
          this.$router.go(-1)
        } else {
          this.$toast({
            message: res.msg
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.driverdetils {
  height: 100%;
  background: #F1F5F8;

  .infor {
    height: 250px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0px 0px 8px 1px #eee;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .hint {
      margin-top: 10px;
    }
    .price {
      font-size: 34px;
      font-weight: 400;
      color: #333;
      span {
        font-size: 28px;
      }
    }
    .modification {
      margin-top: 10px;
      span {
        color: #3235D7;
      }
    }
  }
  .expenditure-record {
    margin-top: 10px;
  }
  .btn_group {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .btn {
      width: 44%;
    }
  }
  .add-reduce {
    display: flex;
    .add-reduce-style {
      padding: 3px 5px;
      border: 1px solid #999;
      font-size: 12px;
      height: 20px;
      line-height: 12px;
    }
    .add-reduce-style:first-child {
      margin-right: 5px;
    }
    .add-reduce-active {
      border: 1px solid #07c160;
      color: #07c160;
    }
  }
}
</style>

<style lang="scss">
.driverdetils {
  .van-list__finished-text {
    width: 375px;
  }
}
</style>
